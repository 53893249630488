import './App.css';
import Navbar from './Components/Navbar';
import BodyContent from './Components/BodyContent';
import Footer from './Components/Footer';
import About from './Components/About';
import Career from './Components/Career';
import Services from './Components/Services';
import Contact from './Components/Contact';
import Signup from './Components/Signup';
import Login from './Components/Login';
import CookieConsentComponent from './Components/CookieConsent'; 
import { HashRouter as Router, Routes, Route } from 'react-router-dom';
import Service1 from './Components/Services/Service1';
import Service2 from './Components/Services/Service2';
import Service3 from './Components/Services/Service3';
import Service4 from './Components/Services/Service4';
import Service5 from './Components/Services/Service5';
import Service6 from './Components/Services/Service6';
import Service7 from './Components/Services/Service7';
import Service8 from './Components/Services/Service8';
import Service9 from './Components/Services/Service9';
import Service10 from './Components/Services/Service10';
import Service11 from './Components/Services/Service11';
import Service12 from './Components/Services/Service12';
import Service13 from './Components/Services/Service13';
import TermsCondition from './Components/T&C/TermsCondition.jsx'
import Overview from './Components/NavContent/OverviewContent/Overview';
import PrivacyPolicy from './Components/PrivacyPolicy/PrivacyPolicy';
import CorporateCompliances from './Components/Services/CorporateCompliances.jsx';
import AuditTaxation from './Components/Services/AuditTaxation.jsx';
import LegalServices from './Components/Services/LegalServices.jsx';


function App() {
  return (
  <>
  <Router>
  <Navbar />
  <CookieConsentComponent />
  <Routes>
    <Route path="/" element={<BodyContent />} />
    <Route path="/about" element={<About />} />
    <Route path="/career" element={<Career />} />
    <Route path="/services" element={<Services />} />
    <Route path="/contact" element={<Contact />} />
    <Route path="/signup" element={<Signup/>}/>
    <Route path="/login" element={<Login/>}/>
    <Route path="/service1" element={<Service1/>}/>
    <Route path="/service2" element={<Service2/>}/>
    <Route path="/service3" element={<Service3/>}/>
    <Route path="/service4" element={<Service4/>}/>
    <Route path="/service5" element={<Service5/>}/>
    <Route path="/service6" element={<Service6/>}/>
    <Route path="/service7" element={<Service7/>}/>
    <Route path="/service8" element={<Service8/>}/>
    <Route path="/service9" element={<Service9/>}/>
    <Route path="/service10" element={<Service10/>}/>
    <Route path="/service11" element={<Service11/>}/>
    <Route path="/service12" element={<Service12/>}/>
    <Route path="/service13" element={<Service13/>}/>
    <Route path='/overview' element={<Overview/>}/>
    <Route path='/privacy' element={<PrivacyPolicy/>}/>
    <Route path='/terms_condition' element={<TermsCondition/>}/>
    <Route path='/corporate' element={<CorporateCompliances/>}/>
    <Route path='/audit' element={<AuditTaxation/>}/>
    <Route path='/legal' element={<LegalServices/>}/>

  </Routes>
  <Footer />
</Router>

  </>  
  );
}

export default App;
