import React from 'react';
import './OurThinking.css';
import '../Trends/Trends.css';

export default function OurThinking() {
  return (
    <>
      <section className="menu section-padding">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2 className="trending-heading">Our Thinking</h2>
            </div>

            <div class="col-lg-4 col-md-6 col-12">
              <div class="menu-thumb">
                <a href="#" class="d-block">
                  <div class="menu-image-wrap">
                    <img src="images/ServiceIMG/corporate_compliances.jpg" class="img-fluid menu-image" alt="" />
                  </div>
                  <div className="menu-info d-flex flex-wrap align-items-center">
                    <div className="d-flex flex-wrap align-items-center w-100 mt-2">
                      <p className="small-text">Our thinking is driven by innovation, empathy, and a relentless pursuit of excellence.</p>
                    </div>
                  </div>
                </a>
              </div>
            </div>

            <div className="col-lg-4 col-md-5 col-12">
              <div className="menu-thumb">
                <a href="" className="d-block">
                  <div className="menu-image-wrap">
                    <img src="images/ServiceIMG/Default_corporate_compliances_with_white_background_3.jpg" className="img-fluid menu-image" alt="Audit & Taxation" />
                  </div>
                  <div className="menu-info d-flex flex-wrap align-items-center">
                    <div className="d-flex flex-wrap align-items-center w-100 mt-2">
                      <p className="small-text">
                        Deforestation significantly contributes to climate change by reducing the Earth's capacity to absorb carbon dioxide.</p>
                    </div>
                  </div>
                </a>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 col-12">
              <div className="menu-thumb">
                <a href="" className="d-block">
                  <div className="menu-image-wrap">
                    <img src="images/ServiceIMG/ourthinking2.jpeg" className="img-fluid menu-image" alt="" />
                  </div>

                  <div className="menu-info d-flex flex-wrap align-items-center">
                    <div className="d-flex flex-wrap align-items-center w-100 mt-2">
                      <p className="small-text">The stock market reflects investor confidence and economic health, influencing and being influenced by economic trends and policies.</p>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
