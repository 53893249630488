import React, { useEffect, useRef } from 'react';
import './Trends.css';

const Trends = () => {
    const trends = [
        { text: 'SEC chief accountant calls:', date: '2024-05-25 | 12:22 AM', image: 'https://d6jxgaftxvagq.cloudfront.net/Pictures/380x253/9/4/2/18942_seclease_581009.jpg' },
        // { text: 'Survey Report:', date: '2024-05-02 | 7:16 AM', image: 'https://d6jxgaftxvagq.cloudfront.net/Pictures/380x253/8/7/3/18873_navexthumbnail_824070.jpg' },
        { text: '​SEC official clarifies:', date: '2024-05-22 | 10:05 PM', image: 'https://d6jxgaftxvagq.cloudfront.net/Pictures/380x253/9/7/7/18977_cybersecurity_589909.jpg' },
        // { text: 'Lawsuit:', date: '2024-05-14 | 12:33 AM', image: 'https://d6jxgaftxvagq.cloudfront.net/Pictures/380x253/9/2/3/18923_lawsuit_233449.jpg' },
        // { text: 'Trend 5', date: '2024-05-21', image: 'https://via.placeholder.com/300x150' },
    ];

    const scrollRef = useRef(null);

    useEffect(() => {
        const interval = setInterval(() => {
            if (scrollRef.current) {
                scrollRef.current.scrollLeft += 1;
            }
        }, 50);

        return () => clearInterval(interval);
    }, []);

    return (
        // <div className="container trends-section">
        //     <div className="container">
        //         <div className="row">
        //             <div className="col-12">
        //                 <h2 className="trending-heading">Trending Now</h2>
        //             </div>
        //             <div className="trends-container" ref={scrollRef}>
        //                 <div className="trends-row d-flex flex-nowrap">
        //                     {trends.map((trend, index) => (
        //                         <div key={index} className="trend-card">
        //                             <div className="card">
        //                                 <div className="image-overlay-container">
        //                                     <img src={trend.image} className="card-img-top" alt={trend.text} />
        //                                     <div className="overlay">
        //                                         <h5 className="card-title">{trend.text}</h5>
        //                                     </div>
        //                                 </div>
        //                                 <button className="btn btn-discover">Explore More</button>
        //                             </div>
        //                             <div className="card-info">
        //                                 <p className="card-date">{trend.date}</p>
        //                             </div>
        //                         </div>
        //                     ))}
        //                 </div>
        //             </div>
        //         </div>
        //     </div>
        // </div>

        <div className="container trends-section">
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <h2 className="trending-heading">Trending Now</h2>
                </div>
                <div className="trends-container" ref={scrollRef}>
                    <div className="trends-row">
                        {trends.map((trend, index) => (
                            <div key={index} className="trend-card">
                                <div className="card">
                                    <div className="image-overlay-container">
                                        <img src={trend.image} className="card-img-top" alt={trend.text} />
                                        <div className="overlay">
                                            <h5 className="card-title">{trend.text}</h5>
                                        </div>
                                    </div>
                                    <button className="btn btn-discover">Explore More</button>
                                </div>
                                <div className="card-info">
                                    <p className="card-date">{trend.date}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    </div>
    );
};

export default Trends;
