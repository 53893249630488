import React from 'react';
import './Quicklinks.css';

const QuickLinks = () => {

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }

    return (
        <div className="quick-links">
            <a href="/" className="quick-link" onClick={scrollToTop} >Home</a>
            <a href="/#/about" className="quick-link" onClick={scrollToTop} >About</a>
            <a href="/#/services" className="quick-link" onClick={scrollToTop} >Services</a>
            <a href="/#/career" className="quick-link" onClick={scrollToTop} >Career</a>
            <a href="/#/contact" className="quick-link" onClick={scrollToTop} >Contact</a>
        </div>
    );
};

export default QuickLinks;
