import React from 'react'
import './Services.css'

const CorporateCompliances = () => {
    const scrollToTop = () => {
        window.scrollTo({
            top: 550,
            behavior: 'smooth'
        });
    };
    return (
        <>
            <div className="container-c">
                    <div className="text-column-c">
                        <div className="image-container-h">
                            <h3 className="centered-heading">Corporate Compliances</h3>
                        </div>
                        <div id="service1" className="service">
                            <div className="content">
                                <div className="text-column">
                                    <h3 className='head'>Structuring and Organizing Business</h3>
                                    <p className='head_sum'>We provide comprehensive services for acquiring various licenses necessary for your company's operations. Our services cover a wide range of licenses, including business permits, professional licenses, industry-specific certifications, and regulatory approvals. We facilitate the application process, handle paperwork, and ensure compliance with relevant authorities.</p>
                                </div>
                                <div className="image-column">
                                    <img src="images/ServicesNavBar/CC1.jpg" alt="Image description" className="img-left" />
                                    <a href="/#/contact" className="contactButton" onClick={scrollToTop}>Contact us</a>
                                </div>
                            </div>
                        </div>
                        <div id="service2" className="service">
                            <div className="content">
                                <div className="text-column">
                                    <h3 className='head'>Foreign Direct Investment</h3>
                                    <p className='head_sum'>We specialize in guiding companies through the complexities of foreign direct investment. Our services are designed to help you capitalize on global opportunities and include:</p>
                                    <ul className="list">
                                        <li className="list-item">Market Entry Strategy: Analysing potential markets to determine the most viable entry strategies, identifying local compliance requirements, and evaluating risks and benefits.</li>
                                        <li className="list-item">Regulatory Navigation: Assisting with navigating through the regulatory frameworks of target countries, including compliance with local laws and international investment treaties.</li>
                                        <li className="list-item">Investment Structuring: Advising on the structuring of your investment to optimize tax efficiency and align with your business goals.</li>
                                        <li className="list-item">Partnership and Joint Ventures: Facilitating partnerships and joint ventures with local entities to enhance market presence and operational capabilities.</li>
                                    </ul>
                                    <p className='head_sum'> Our goal is to ensure your foreign direct investment is strategically sound, compliant, and poised for success.</p>
                                </div>
                                <div className="image-column">
                                    <img src="images/ServicesNavBar/CC2.jpg" alt="Image description" className="img-left" />
                                    <a href="/#/contact" className="contactButton" onClick={scrollToTop}>Contact us</a>                                </div>
                            </div>
                        </div>
                        <div id="service3" className="service">
                            <div className="content">
                                <div className="text-column">
                                    <h3 className='head'>Business License Spectrum</h3>
                                    <p className='head_sum'>We provide comprehensive services for acquiring various licenses necessary for your company's operations. Our services cover a wide range of licenses, including business permits, professional licenses, industry-specific certifications, and regulatory approvals. We facilitate the application process, handle paperwork, and ensure compliance with relevant authorities.</p>
                                </div>
                                <div className="image-column">
                                    <img src="images/ServicesNavBar/CC3.jpg" alt="Image description" className="img-left" />
                                    <a href="/#/contact" className="contactButton" onClick={scrollToTop}>Contact us</a>
                                </div>
                            </div>
                        </div>
                        <div id="service4" className="service">
                            <div className="content">
                                <div className="text-column">
                                    <h3 className='head'>Merger and Acquisition</h3>
                                    <p className='head_sum'>We offer comprehensive advisory services for mergers and acquisitions to help your business successfully manage and execute M&A transactions. Our services include:</p>
                                    <ul className="list">
                                        <li className="list-item">Valuation and Modelling: Assessing the value of potential acquisition targets and merger partners.</li>
                                        <li className="list-item">Due Diligence: Conducting thorough investigations to ensure all financial, operational, and legal aspects are transparent.</li>
                                        <li className="list-item">Negotiation Support: Assisting in the negotiation process to achieve optimal transaction terms.</li>
                                        <li className="list-item">Transaction Structuring: Designing the structure of the transaction to align with strategic goals and compliance requirements.</li>
                                        <li className="list-item">Integration Planning: Providing guidance on the integration of operations, cultures, and systems post-acquisition.</li>
                                    </ul>
                                    <p className='head_sum'>Our goal is to support your business through each step of the merger or acquisition, ensuring a smooth transition and strategic alignment with your long-term objectives.</p>
                                </div>
                                <div className="image-column">
                                    <img src="images/ServicesNavBar/CC4.jpg" alt="Image description" className="img-left" />
                                    <a href="/#/contact" className="contactButton" onClick={scrollToTop}>Contact us</a>
                                </div>
                            </div>
                        </div>
                        <div id="service5" className="service">
                            <div className="content">
                                <div className="text-column">
                                    <h3 className='head'>Company Registration And Compliance</h3>
                                    <p className='head_sum'>We specialize in Company Registration and Compliance services, assisting businesses with the formation and registration of companies, partnerships, and other business entities. Our services also encompass ongoing compliance support, including annual filings, regulatory compliance, corporate governance, and statutory obligations.</p>
                                </div>
                                <div className="image-column">
                                    <img src="images/ServicesNavBar/CC5.jpg" alt="Image description" className="img-left" />
                                    <a href="/#/contact" className="contactButton" onClick={scrollToTop}>Contact us</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        </>
    )
}

export default CorporateCompliances
