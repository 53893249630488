import React from 'react'
import './Services.css'

const AuditTaxation = () => {

  const scrollToTop = () => {
    window.scrollTo({
        top: 550,
        behavior: 'smooth'
    });
};
  return (
    <>
      <div className="container-c">
        <div className="content-wrapper-c">
          <div className="text-column-c">
            <div className="image-container-h">
              <h3 className="centered-heading">Audit & Taxation</h3>
            </div>
            <div id="service6" className="service">
              <div className="content">
                <div className="text-column">
                  <h3>Banking & Financial Services</h3>
                  <p>We provide comprehensive Banking and Financial Services to meet your personal and business needs. Our services include savings accounts, checking accounts, loans, investment options, wealth management, and financial advisory services. Whether you're looking for basic banking solutions or complex financial strategies, our team is here to assist you in achieving your financial goals.</p>
                </div>

                <div className="image-column">
                  <img src="images/ServicesNavBar/AT1.jpg" alt="Image description" class="img-left" />
                  <a href="/#/contact" class="contactButton" onClick={scrollToTop}>Contact us</a>

                </div>
              </div>
            </div>
            <div id="service7" className="service">
              <div className="content">
                <div class="text-column">
                  <h3> Audit, Accounting & Taxation Services</h3>
                  <p>
                    We provide a comprehensive suite of audit, accounting, and taxation services tailored to meet the needs of your business. Our services include:
                  </p><br />

                  <ul className="list">
                    <li className="list-item">Audit and Assurance: Ensuring compliance with the latest financial regulations and standards, we provide detailed audits that help you understand the true financial state of your business.</li>

                    <li className="list-item">Accounting Solutions: From day-to-day bookkeeping to complex financial reporting, our services are designed to keep your financial records accurate and up to date.</li>

                    <li className="list-item">Tax Advisory and Compliance: We offer expert tax advice and planning strategies to optimize your tax position, ensuring compliance with local and international tax laws.</li>

                  </ul>
                </div>
                <div className="image-column">
                  <img src="images/ServicesNavBar/AT2.jpg" alt="Image description" class="img-left" />
                  <a href="/#/contact" class="contactButton" onClick={scrollToTop}>Contact us</a>

                </div>
              </div>
            </div>
            <div id="service8" className="service">
              <div className="content">
                <div className="text-column">
                  <h3>GST & Other Related Services</h3>
                  <p>With new regime of GST tax various made easy to run business in India to entrepreneurs. We are offering following services.</p><br />

                  <ul className="list">
                    <li className="list-item">GST Registration & Migration</li>
                    <li className="list-item">GST Returns</li>
                    <li className="list-item">GST Accounts & records</li>
                    <li className="list-item">GST System development</li>
                    <li className="list-item">GST seminar & trainings</li>
                    <li className="list-item">GST Audit & Valuations</li>
                    <li className="list-item">GST Consultancy</li>
                    <li className="list-item">GST Representations</li>
                  </ul>
                </div>
                <div className="image-column">
                  <img src="images/ServicesNavBar/AT3.jpg" alt="Image description" class="img-left" />
                  <a href="/#/contact" class="contactButton" onClick={scrollToTop}>Contact us</a>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AuditTaxation
