import React from 'react'

const Service5 = () => {
    return (
        <>
            <section className="news-detail section-padding pt-0">
                <div className="container">
                    <div className="row">

                        <div className="col-lg-12 col-12">


                            <div className="col-lg-10 col-10 mx-auto mt-5">

                                <h4 className="mb-3">Merger and Acquisition </h4>

                                <p>
                                    We offer comprehensive advisory services for mergers and acquisitions to help your business successfully manage and execute M&A transactions. Our services include:

                                </p><br />
                                <ul className="list">
                                    <li className="list-item">Valuation and Modelling: Assessing the value of potential acquisition targets and merger partners.</li>

                                    <li className="list-item">Due Diligence: Conducting thorough investigations to ensure all financial, operational, and legal aspects are transparent.</li>

                                    <li className="list-item">
                                        Negotiation Support: Assisting in the negotiation process to achieve optimal transaction terms.
                                    </li>
                                    <li className="list-item">Transaction Structuring: Designing the structure of the transaction to align with strategic goals and compliance requirements.</li>
                                    <li className="list-item">Integration Planning: Providing guidance on the integration of operations, cultures, and systems post-acquisition.
                                    </li>
                                    <br />
                                    <p>Our goal is to support your business through each step of the merger or acquisition, ensuring a smooth transition and strategic alignment with your long-term objectives.</p>
                                </ul>

                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </>
    )
}

export default Service5