import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';


function Signup() {
  const [userName, setUserName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [role, setRole] = useState('');
  const [errors, setErrors] = useState({});
  const [signupSuccess, setSignupSuccess] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [submitMessage, setSubmitMessage] = useState('');

  const submit = (e) => {
    e.preventDefault();
    localStorage.setItem('name', userName); // Store user name in localStorage
    setSubmitting(true);
    const newErrors = {};

    if (userName.trim() === '') {
      newErrors.userName = 'User Name is required';
    }

    if (email.trim() === '') {
      newErrors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      newErrors.email = 'Invalid email';
    }

    if (phoneNumber.trim() === '') {
      newErrors.phoneNumber = 'Phone Number is required';
    } else if (!/^\d{10}$/.test(phoneNumber)) {
      newErrors.phoneNumber = 'Invalid phone number';
    }

    if (password.trim() === '') {
      newErrors.password = 'Password is required';
    } else if (password.length < 8) {
      newErrors.password = 'Password must be at least 8 characters';
    }

    if (confirmPassword.trim() === '') {
      newErrors.confirmPassword = 'Confirm Password is required';
    } else if (confirmPassword !== password) {
      newErrors.confirmPassword = 'Passwords do not match';
    }

    if (role.trim() === '') {
      newErrors.role = 'Role is required';
    }

    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {

        axios.post('http://localhost:8004/signup', {
          userName,
          email,
          phoneNumber,
          password,
          role
        })
        .then(response => {
            console.log(response);
            setSignupSuccess(true);
            setSubmitMessage('Signup successful!');
          })
          .catch(error => {
            console.error('Error signing up:', error);
            setSubmitMessage('Error signing up. Please try again.');
          })
          .finally(() => {
            setSubmitting(false);
          });
        } else {
          setSubmitting(false);
        }
      };

  return (
    <div className="login container d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
      <div className="card shadow p-5" style={{ width: '400px' }}>
        <h1 className="text-center mb-4">Signup</h1>
        {signupSuccess ? (
          <div className="alert alert-success" role="alert">
            Signup Successful
          </div>
        ) : (
        <form onSubmit={submit}>
          <div className="mb-3">
            <input type="text" value={userName} onChange={(e) => setUserName(e.target.value)} className="form-control" placeholder="User Name" />
            {errors.userName && <div className="text-danger">{errors.userName}</div>}
          </div>
          <div className="mb-3">
            <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} className="form-control" placeholder="Email" />
            {errors.email && <div className="text-danger">{errors.email}</div>}
          </div>
          <div className="mb-3">
            <input type="tel" value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} className="form-control" placeholder="Phone Number" />
            {errors.phoneNumber && <div className="text-danger">{errors.phoneNumber}</div>}
          </div>
          <div className="mb-3">
            <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} className="form-control" placeholder="Password" />
            {errors.password && <div className="text-danger">{errors.password}</div>}
          </div>
          <div className="mb-3">
            <input type="password" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} className="form-control" placeholder="Confirm Password" />
            {errors.confirmPassword && <div className="text-danger">{errors.confirmPassword}</div>}
          </div>
          <div className="mb-3">
            <div className="form-check form-check-inline">
              <input className="form-check-input" type="radio" name="role" value="Employee" checked={role === 'Employee'} onChange={(e) => setRole(e.target.value)} />
              <label className="form-check-label">Employee</label>
            </div>
            <div className="form-check form-check-inline">
              <input className="form-check-input" type="radio" name="role" value="User" checked={role === 'User'} onChange={(e) => setRole(e.target.value)} />
              <label className="form-check-label">User</label>
            </div>
            {errors.role && <div className="text-danger">{errors.role}</div>}
          </div>
          <button type="submit" className="btn btn-primary w-100">
              {submitting ? 'Submitting...' : 'Submit'}
            </button>          
          {submitMessage && (
              <div className="alert alert-info" role="alert">
                {submitMessage}
              </div>
            )}
        </form>
       )}
        <hr className="my-4" />

        <div className="text-center">
          <p>OR</p>
          <Link to="/login" className="btn btn-outline-secondary">Login Page</Link>
        </div>
      </div>
    </div>
  );
}

export default Signup;
