// import React from 'react'
// import './NavAbout.css'
// import { Link } from 'react-router-dom'

// const NavAbout = () => {

//     return (
//         <>
//             <div className="mega-menu-container">
//                 <div className="column">
//                     <h6><Link to="/overview"  rel="noopener noreferrer">Overview</Link></h6>
//                     <h6><Link to="/office-location"  rel="noopener noreferrer">Office Location</Link></h6>
//                     <h6><Link to="/social-media"  rel="noopener noreferrer">Social Media</Link></h6>
//                     {/* <h6><Link to="/teams#team"  rel="noopener noreferrer">Teams</a></h6> */}
//                 </div>
//                 <div className="column">
//                     <h6><Link to="/about"  rel="noopener noreferrer">About Us</Link></h6>
//                     <p>Our aim is to build long-term relationships with our clients by earning their trust and delivering superior client service, which is enabled by our talented team of Lawyers, Company secretaries, Chartered accountants and professionals. Sharing knowledge is a key to earn trust; </p>
//                 </div>
//             </div>
//         </>
//     )
// }

// export default NavAbout


import React, { useState } from 'react';
import './NavAbout.css';
import { Link } from 'react-router-dom';

const NavAbout = () => {
    const [menuOpen, setMenuOpen] = useState(true); // State to track if menu is open or closed

    // Function to close the menu
    const closeMenu = () => {
        setMenuOpen(false);
    };

    return (
        <>
            {menuOpen && (
                <div className="mega-menu-container">
                    <div className="column">
                        <h6><a href="/#/overview" onClick={closeMenu} rel="noopener noreferrer">Overview</a></h6>
                        <h6><Link to="/office-location" onClick={closeMenu} rel="noopener noreferrer">Office Location</Link></h6>
                        <h6><Link to="/social-media" onClick={closeMenu} rel="noopener noreferrer">Social Media</Link></h6>
                        {/* <h6><Link to="/teams#team"  rel="noopener noreferrer">Teams</a></h6> */}
                    </div>
                    <div className="column">
                        <h6><Link to="/about" onClick={closeMenu} rel="noopener noreferrer">About Us</Link></h6>
                        <p>Our aim is to build long-term relationships with our clients by earning their trust and delivering superior client service, which is enabled by our talented team of Lawyers, Company secretaries, Chartered accountants and professionals. Sharing knowledge is a key to earn trust; </p>
                    </div>
                </div>
            )}
        </>
    );
};

export default NavAbout;

