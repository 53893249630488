import React from 'react'

const Service12 = () => {
  return (
    <>
      <section className="news-detail section-padding pt-0">
                <div className="container">
                    <div className="row">

                        <div className="col-lg-12 col-12">
                           

                            <div className="col-lg-10 col-10 mx-auto mt-5">
                                
                                <h4 className="mb-3">Corporate Drafting and Litigation </h4>

                                <p>We providing expert legal support for businesses in drafting corporate documents, contracts, and agreements, as well as representing clients in corporate litigation matters. We offer tailored solutions to address your corporate legal needs efficiently and effectively.

                                </p><br/>
                                                               
                            </div>
                        </div>

                    </div>
                </div>
            </section>
    </>
  )
}

export default Service12
