import React from 'react'
import './OurServices.css'

export const OurServices = () => {

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    return (
        <div>
            <section className="menu section-padding">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <h2 className="trending-heading">Our Services</h2>
                        </div>
                        <div className="col-lg-4 col-md-6 col-12">
                            <div className="menu-thumb">
                                <a href="/#/corporate" onClick={scrollToTop}  className="d-block">
                                    <div className="menu-image-wrap">
                                        <img src="images/ServiceIMG/Default_corporate_compliances_with_human_Business_with_white_b_2.jpg" className="img-fluid menu-image" alt="" />
                                    </div>

                                    <div className="menu-info d-flex flex-wrap align-items-center">
                                        <h4 className="text-center mb-0 service-title">Corporate Compliances</h4>
                                        <div className="d-flex flex-wrap align-items-center w-100 mt-2">
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-5 col-12">
                            <div className="menu-thumb">
                                <a href="/#/audit" onClick={scrollToTop}  className="d-block">
                                    <div className="menu-image-wrap">
                                        <img src="images/ServiceIMG/Default_audit_and_taxation_with_white_background_png_image_1.jpg" className="img-fluid menu-image" alt="Audit & Taxation" />
                                    </div>
                                    <div className="menu-info d-flex flex-wrap align-items-center">
                                        <h4 className="text-center mb-0 service-title">Audit & Taxation</h4>
                                    </div>
                                </a>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 col-12">
                            <div className="menu-thumb">
                                <a href="/#/legal" onClick={scrollToTop} className="d-block">
                                    <div className="menu-image-wrap">
                                        <img src="images/ServiceIMG/Default_legal_services_with_white_background_png_image_1.jpg" className="img-fluid menu-image" alt="" />
                                    </div>

                                    <div className="menu-info d-flex flex-wrap align-items-center">
                                        <h4 className=" text-center mb-0 service-title">Legal Services</h4>
                                        <div className="d-flex flex-wrap align-items-center w-100 mt-2">
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}
