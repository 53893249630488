import React from 'react'
import './T&C/TermsCondition'
import { Link } from 'react-router-dom'

const About = () => {
    return (
        <>
            <div>

                <header className="site-header site-about-header">
                    <div className="container">
                        <div className="row">

                            <div className="col-lg-10 col-12 mx-auto">
                                <h1 className="text-white">About Us</h1>

                                <strong className="text-white">"Building Success: The Story Behind Maa Pranaam Suvidha Limited"</strong>
                            </div>

                        </div>
                    </div>
                    <div className="video-wrap">
                        <video autoPlay="" loop="" muted="" className="custom-video" poster="">
                            <source src="video/story_tiny.mp4" type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                    </div>
                    <div className="overlay"></div>
                </header>

                <section className="about section-padding bg-white">
                    <div className="container">
                        <div className="row">

                            <div className="col-lg-6 col-12 mb-2">
                                <h4 className="mb-3">Maa Pranaam Suvidha Limited</h4>

                                <Link to="/services" className="custom-btn btn btn-dark mt-3">Services</Link>

                                <Link to="/contact" className="custom-btn btn btn-danger mt-3 ms-3">Say Hi</Link>
                            </div>

                            <div className=" abt-p col-lg-6 col-12">

                                <p>Maa Pranaam Suvidha Limited, a company based in Pune, offers a diverse portfolio of services in the field of Corporate Laws Services, FEMA, Intellectual Property Rights (IPR), Labour Law, Company Law, Accounting, Auditing, Direct Taxation, Indirect Taxation, Representation of Various Authorities, SME Listing, Registration and related work to partnerships and financial consultancy to its clients, maintaining highest standard of integrity and confidentiality.</p>

                                <p>Our aim is to build long-term relationships with our clients by earning their trust and delivering superior client service, which is enabled by our talented team of Lawyers, Company secretaries, Chartered accountants and professionals. Sharing knowledge is a key to earn trust; it has helped us and our clients in tremendous ways to benefit from our business relationship and combined knowledge in the business domain.

                                </p>

                            </div>

                        </div>
                    </div>
                </section>

                <section id="#sec-overview" className="about section-padding">
                    <div className="container">
                        <div className="row">

                            <div className="col-12">
                                <h2 className="mb-5">Team Members</h2>
                            </div>

                            <div className="col-lg-4 col-12">
                                <div className="team-thumb">
                                    {/* <img src="images/team/rekha_pawar.jpg" className="img-fluid team-image" alt="" /> */}

                                    <div className="team-info">
                                        <h4 className="mt-3 mb-0">Mrs.Rekha Pawar</h4>

                                        <p>Director</p>
                                        <p>Master in Business Administration</p>
                                        <p1>Social Media Activities. She is a proprietor of INVESTMENT JUNCTION and has approximately 15 years of experience in the insurance business.She also has a good client base.She possesses the qualities of a Team Leader with experience in managing large teams and delivering projects on time.Investment Junction is registered under Maha E seva for providing PAN card, AADHAR card, Shop Act, Passport, etc. and also provides registration under various types of government services.</p1>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-12 my-lg-0 my-4">
                                <div className="team-thumb">
                                    {/* <img src="images/team/vishal_pawar.jpg" className="img-fluid team-image" alt="" /> */}

                                    <h4 className="mt-3 mb-0">Mr.Vishal Pawar</h4>

                                    <p>Director</p>
                                    <p>Master in Business Administration</p>
                                    <p1>Mr. Vishal had work experience under insurance business approximately for 8 years & also have good client base. He is also engaged in the business of construction since 7 years. In pune city 3 projects are completely hand over & 6 projects are about to possession of more than 1000 flat units.</p1>
                                </div>
                            </div>

                            <div className="col-lg-4 col-12">
                                <div className="team-thumb">
                                    {/* <img src="images/team/Hemant_pawar.jpg" className="img-fluid team-image" alt="" /> */}

                                    <h4 className="mt-3 mb-0">Mr.Hemant Pawar</h4>

                                    <p>CEO & Founder</p>
                                    <p>Company secretaroy</p>
                                    <p1>Social Media Activities. Mr. Hemant had worked as MDRT in KOTAK LIFE INSURANCE for 2 terms, 10 years experience related to life insurance. He had also Working experience as an agent with AVIVA LIFE INSURANCE COMPANY INDIA LIMITED from 18-07-2011 TO 17-07-2014. He is engaged in the consultancy business related to various laws and compliances related to company, LLP, partnership firm.</p1>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}

export default About
