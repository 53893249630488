import React, { useState, useEffect } from 'react';
import './ClientReviews.css';
import { Navigation, Pagination } from 'swiper/modules';
import Swiper from 'swiper/bundle';
import 'swiper/css/bundle';

const ClientReviews = () => {
    const [swiper, setSwiper] = useState(null);

    useEffect(() => {
        const swiperInstance = new Swiper('.testimonials-slider', {
            modules: [Navigation, Pagination],
            slidesPerView: 2,
            spaceBetween: 30,
            pagination: {
                el: '.swiper-pagination',
                clickable: true,
            },
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
        });
        setSwiper(swiperInstance);
    }, []);

    return (
        <section className="testimonials">
            <div className="container">
                <div className="section-header">
                    <h2 className="trending-heading">Clients Reviews</h2>
                    <div className='testimonials-content'>
                        <div className="swiper testimonials-slider">
                            <div className="swiper-wrapper">
                                <div className='swiper-slide testimonials-item'>
                                    <div className="info">
                                        <img src="images/clients/Kishor.jpg" alt="" />
                                        <div className="text-box">
                                            <h3 className="name">Kishor More</h3>
                                            <span className="job">Sr. S/W Developer</span>
                                        </div>
                                    </div>
                                    <p>Excellent service and support from the team. They were very responsive and helped me resolve my issues quickly.</p>
                                    <div className="rating">
                                        <i className="fa fa-star"></i>
                                        <i className="fa fa-star"></i>
                                        <i className="fa fa-star"></i>
                                        <i className="fa fa-star"></i>
                                        <i className="fa fa-star"></i>
                                    </div>
                                </div>
                                <div className='swiper-slide testimonials-item'>
                                    <div className="info">
                                        <img src="images/clients/Prince.jpg" alt="phtoto" />
                                        <div className="text-box">
                                            <h3 className="name">Prince Kumar</h3>
                                            <span className="job">S/W Developer</span>
                                        </div>
                                    </div>
                                    <p>The team was very professional and knowledgeable. They helped me with all my questions and concerns.</p>
                                    <div className="rating">
                                        <i className="fa fa-star"></i>
                                        <i className="fa fa-star"></i>
                                        <i className="fa fa-star"></i>
                                        <i className="fa fa-star"></i>
                                        <i className="fa fa-star"></i>
                                    </div>
                                </div>
                                <div className='swiper-slide testimonials-item'>
                                    <div className="info">
                                        <img src="images/clients/sid.jpg" alt="" />
                                        <div className="text-box">
                                            <h3 className="name">Siddharth Dhole</h3>
                                            <span className="job">S/W Developer</span>
                                        </div>
                                    </div>
                                    <p>Their customer support is top-notch. They were very helpful and patient with me.</p>
                                    <div className="rating">
                                        <i className="fa fa-star"></i>
                                        <i className="fa fa-star"></i>
                                        <i className="fa fa-star"></i>
                                        <i className="fa fa-star"></i>
                                        <i className="fa fa-star"></i>
                                    </div>
                                </div>
                                <div className='swiper-slide testimonials-item'>
                                    <div className="info">
                                        <img src="images/clients/Tushar.jpg" alt="" />
                                        <div className="text-box">
                                            <h3 className="name">Tushar Marewad</h3>
                                            <span className="job">S/W Developer</span>
                                        </div>
                                    </div>
                                    <p>I was impressed with the quality of their work and the professionalism of their team.</p>
                                    <div className="rating">
                                        <i className="fa fa-star"></i>
                                        <i className="fa fa-star"></i>
                                        <i className="fa fa-star"></i>
                                        <i className="fa fa-star"></i>
                                        <i className="fa fa-star"></i>
                                    </div>
                                </div>

                                <div className='swiper-slide testimonials-item'>
                                    <div className="info">
                                        <img src="images/clients/client1.jpg" alt="" />
                                        <div className="text-box">
                                            <h3 className="name">Sammer Shaik</h3>
                                            <span className="job">S/W Developer</span>
                                        </div>
                                    </div>
                                    <p>Maa Pranaam Suvidha delivers top-notch tax, audit, consultancy, and legal services. Highly professional and reliable!</p>
                                    <div className="rating">
                                        <i className="fa fa-star"></i>
                                        <i className="fa fa-star"></i>
                                        <i className="fa fa-star"></i>
                                        <i className="fa fa-star"></i>
                                        <i className="fa fa-star"></i>
                                    </div>
                                </div>

                            </div>
                            <div className="swiper-pagination"></div>
                            <div className="swiper-button-prev"></div>
                            <div className="swiper-button-next"></div>
                        </div>
                    </div>
                    <div className="swiper-pagination js-testimonials-pagination"></div>
                </div>
            </div>
        </section>
    );
};

export default ClientReviews;