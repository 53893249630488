import React from 'react'

const Service2 = () => {
  return (
    <>
       <section className="news-detail section-padding pt-0">
                <div className="container">
                    <div className="row">

                        <div className="col-lg-12 col-12">
                           

                            <div className="col-lg-10 col-10 mx-auto mt-5">
                                
                                <h4 className="mb-3">Foreign Direct Investment</h4>

                                <p>We specialize in guiding companies through the complexities of foreign direct investment. Our services are designed to help you capitalize on global opportunities and include:

                                </p><br/>
                                <ul className="list">
                                    <li className="list-item">Market Entry Strategy: Analysing potential markets to determine the most viable entry strategies, identifying local compliance requirements, and evaluating risks and benefits.</li>

                                    <li className="list-item">Regulatory Navigation: Assisting with navigating through the regulatory frameworks of target countries, including compliance with local laws and international investment treaties.</li>

                                    <li className="list-item">
                                        Investment Structuring: Advising on the structuring of your investment to optimize tax efficiency and align with your business goals.
                                        </li>
                                    <li className="list-item">Partnership and Joint Ventures: Facilitating partnerships and joint ventures with local entities to enhance market presence and operational capabilities.</li>
                                    
                                </ul>
                               
                               <p>Our goal is to ensure your foreign direct investment is strategically sound, compliant, and poised for success.</p>
                                
                            </div>
                        </div>

                    </div>
                </div>
            </section>
    </>
  )
}

export default Service2
