import React, { useState } from 'react';
import CookieConsent from 'react-cookie-consent';
import './Captcha.css';

const CookieConsentComponent = () => {
    const [visible, setVisible] = useState(true);

    const buttonStyle = {
        background: "#007bff",
        color: "#fff",
        fontSize: "14px",
        padding: "8px 16px",
        margin: "0 10px",
        borderRadius: "5px",
        border: "none",
        cursor: "pointer",
        transition: "background-color 0.3s ease"
    };

    const closeButtonStyle = {
        background: "transparent",
        color: "#fff",
        fontSize: "20px",
        padding: "0 10px",
        border: "none",
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    };

    const handleAccept = () => {
        localStorage.setItem('cookieAccepted', 'true');
        console.log('Cookies accepted.');
        setVisible(false);
    };

    const handleClose = () => {
        setVisible(false);
    };

    return (
        visible && (
            <CookieConsent
                location="bottom"
                cookieName="myCookieConsent"
                style={{ background: "#333", color: "#fff", padding: "10px", borderRadius: "5px", boxShadow: "0 2px 5px rgba(0,0,0,0.1)" }}
                expires={365}
            >

                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <div style={{ marginBottom: "1px" }}>
                        We use Cookies to provide our services and enhance your experience. By using this website, you agree with this.
                    </div>
                    <div style={{ display: "inline-flex" }}>
                        <button onClick={handleAccept} style={buttonStyle}>Accept</button>
                        <span onClick={handleClose} style={closeButtonStyle}>&times;</span>
                    </div>
                </div>

            </CookieConsent>
        )
    );
};

export default CookieConsentComponent;

