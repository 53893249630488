import React from 'react'

const Service13 = () => {
  return (
    <>
      
      <section className="news-detail section-padding pt-0">
                <div className="container">
                    <div className="row">

                        <div className="col-lg-12 col-12">
                           

                            <div className="col-lg-10 col-10 mx-auto mt-5">
                                
                                <h4 className="mb-3">Legal Drafts Services</h4>

                                <p>We offer specialized Legal Drafts Services aimed at providing clients with precise and customized legal documents tailored to their specific needs. Our offerings include drafting contracts, agreements, business documents, and other legal forms. These services are crafted by experienced legal professionals to ensure they meet relevant legal standards and requirements.


                                </p><br/>
                                                            
                            </div>
                        </div>

                    </div>
                </div>
            </section>
    </>
  )
}

export default Service13
