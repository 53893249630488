import React from 'react'

const Service11 = () => {
  return (
    <>
       <section className="news-detail section-padding pt-0">
                <div className="container">
                    <div className="row">

                        <div className="col-lg-12 col-12">
                           

                            <div className="col-lg-10 col-10 mx-auto mt-5">
                                
                                <h4 className="mb-3">Intellectual Property</h4>

                                <p>We offer comprehensive Intellectual Property (IP) services to safeguard your creative assets and innovations. Our services include trademark registration, patent applications, copyright protection, IP portfolio management, licensing agreements, and IP litigation support. Whether you're an individual inventor, startup, or established company, we provide tailored solutions to protect and maximize the value of your intellectual property.

                                </p><br/>
                               
                                
                            </div>
                        </div>

                    </div>
                </div>
            </section>
    </>
  )
}

export default Service11
