import React from 'react'

const Service1 = () => {
  return (
    <>
        <section className="news-detail section-padding pt-0">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-12">
              <div className="col-lg-10 col-10 mx-auto mt-5">
                <h4 className="mb-3">Structuring & Organizing Business</h4>

                <p>
                  We provide comprehensive services for acquiring various
                  licenses necessary for your company's operations. Our services
                  cover a wide range of licenses, including business permits,
                  professional licenses, industry-specific certifications, and
                  regulatory approvals. We facilitate the application process,
                  handle paperwork, and ensure compliance with relevant
                  authorities.
                </p>
                <br />
              </div>
            </div>
          </div>
        </div>
      </section>

    </>
  )
}

export default Service1
