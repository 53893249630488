import React from 'react'

const Service8 = () => {
  return (
    <>
           <section className="news-detail section-padding pt-0">
                <div className="container">
                    <div className="row">

                        <div className="col-lg-12 col-12">
                           

                            <div className="col-lg-10 col-10 mx-auto mt-5">
                                
                                <h4 className="mb-3">GST And Other Related Services </h4>

                                <p>With new regime of GST tax various made easy to run business in India to entrepreneurs. We are offering following services.</p><br/>
                                
                                <ul className="list">
                                    <li className="list-item">GST Registration & Migration</li>

                                    <li className="list-item">GST Returns</li>

                                    <li className="list-item">GST Accounts & records</li>
                                    <li className="list-item">GST System development</li>
                                    <li className="list-item">GST seminar & trainings</li>
                                    <li className="list-item">GST Audit & Valuations</li>
                                    <li className="list-item">GST Consultancy</li>
                                    <li className="list-item">GST Representations</li>
                                  
                                </ul>

                                
                            </div>
                        </div>

                    </div>
                </div>
            </section>

    </>
  )
}

export default Service8

