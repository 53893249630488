import React, { useState, useEffect } from 'react';
import axios from "axios";
import './Captcha.css';

const Contact = () => {

  const [captchaText, setCaptchaText] = useState('');
  const [inputValue, setInputValue] = useState('');
  const [message, setMessage] = useState({ text: '', color: '' });
  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(true);
  const [captchaVerified, setCaptchaVerified] = useState(false); // State to track if captcha is verified
  const [captchaError, setCaptchaError] = useState(''); // State to track captcha error message

  // State for form data
  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    email: '',
    message: ''
  });

  useEffect(() => {
    generateCaptcha();
  }, []);

  const generateCaptcha = () => {
    const randomString = Math.random().toString(36).substring(2, 7);
    const randomStringArray = randomString.split("");
    const changeString = randomStringArray.map((char) => (Math.random() > 0.5 ? char.toUpperCase() : char));
    setCaptchaText(changeString.join(" "));
  };

  const handleRefreshClick = (e) => {
    e.preventDefault(); // Prevent the default form submission behavior
    generateCaptcha();
    setInputValue('');
    setMessage({ text: '', color: '' });
    setSubmitButtonDisabled(true);
    setCaptchaVerified(false); // Reset captcha verification status
    setCaptchaError(''); // Reset captcha error message
  };

  const captchaKeyUpValidate = (e) => {
    const value = e.target.value;
    setInputValue(value);
    if (value) {
      setSubmitButtonDisabled(false);
      setMessage({ text: '', color: '' });
      setCaptchaError(''); // Reset captcha error message
    } else {
      setSubmitButtonDisabled(true);
    }
  };

  const handleSubmitClick = (e) => {
    e.preventDefault(); // Prevent the default form submission behavior
    const captchaTextWithoutSpaces = captchaText.split(" ").join("");
    if (inputValue === captchaTextWithoutSpaces) {
      setMessage({ text: 'Entered captcha is correct', color: '#826afb' });
      setCaptchaVerified(true); // Set captcha as verified
      setCaptchaError(''); // Reset captcha error message
    } else {
      setMessage({ text: '', color: '' });
      setCaptchaError('Incorrect captcha, please try again'); // Set captcha error message
      setCaptchaVerified(false); // Set captcha as not verified
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleFormSubmit = (e) => {
    e.preventDefault(); // Prevent the defaul(t form submission behavior
    if (captchaVerified) {
      console.log('Form Data:', formData);
      axios.post("https://mpsglobal.online/api/send-email", formData)
        .then((responce) => {
          alert(responce.data);
        }).catch((error) => {
          console.log(error);
        })
    } else {
      setCaptchaError('Please verify the captcha first');
    }
  };

  return (
    <>
      <div>
        <div class="modal fade" id="myModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Response</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body" id="modalBody">
                {/* Response data will be displayed here  */}
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
              </div>
            </div>
          </div>
        </div>
        <header className="site-header site-contact-header">
          <div className="container">
            <div className="row">
              <div className="col-lg-10 col-12 mx-auto">
                <h1 className="text-white">Say Hi</h1>
                <strong className="text-white">We are happy to get in touch with you</strong>
              </div>
            </div>
          </div>
          <div className="video-wrap">
            <video autoPlay loop muted className="custom-video" poster="">
              <source src="video/contact_large.mp4" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
          <div className="overlay"></div>
        </header>

        <section className="contact section-padding">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <h2 className="mb-4">Leave a message</h2>
              </div>

              <div className="col-lg-6 col-12">
                <form className="custom-form contact-form row" method="post" onSubmit={handleFormSubmit}>
                  <div className="col-lg-6 col-6">
                    <label htmlFor="contact-name" className="form-label">Full Name<a style={{color:"red"}}>*</a></label>
                    <input
                      type="text"
                      name="name"
                      id="contact-name"
                      value={formData.name}
                      onChange={handleInputChange}
                      className="form-control"
                      placeholder="Your Name"
                      required
                    />
                  </div>

                  <div className="col-lg-6 col-6">
                    <label htmlFor="contact-phone" className="form-label">Phone Number <a style={{color:"red"}}>*</a></label>
                    <input
                      type="telephone"
                      name="phone"
                      id="contact-phone"
                      value={formData.phone}
                      onChange={handleInputChange}
                      pattern="[0-9]{3}[0-9]{3}[0-9]{4}"
                      className="form-control"
                      placeholder="Phone Number"
                    />
                  </div>

                  <div className="col-12">
                    <label htmlFor="contact-email" className="form-label">Email<a style={{color:"red"}}>*</a></label>
                    <input
                      type="email"
                      name="email"
                      id="contact-email"
                      value={formData.email}
                      onChange={handleInputChange}
                      pattern="[^ @]*@[^ @]*"
                      className="form-control"
                      placeholder="Your Email"
                      required
                    />

                    <label htmlFor="contact-message" className="form-label">Message<a style={{color:"red"}}>*</a></label>
                    <textarea
                      className="form-control"
                      rows="5"
                      id="contact-message"
                      name="message"
                      value={formData.message}
                      onChange={handleInputChange}
                      placeholder="Your Message"
                    ></textarea>
                  </div>

                  <div className="bdycon">
                    <div className="input_field captch_box">
                      <input type="text" value={captchaText} readOnly />
                      <button className="refresh_button" onClick={handleRefreshClick}>
                        <i className="fa-solid fa-rotate-right" />
                      </button>
                    </div>

                    <div className="input_field captch_input">
                      <input
                        type="text"
                        placeholder="Enter captcha"
                        value={inputValue}
                        onChange={captchaKeyUpValidate}
                      />
                    </div>

                    <div id="message" className="message" style={{ color: message.color }}>
                      {message.text}
                    </div>

                    <div id="captcha-error" className="captcha-error" style={{ color: 'red' }}>
                      {captchaError}
                    </div>

                    <div className="input_field button">
                      <button
                        disabled={submitButtonDisabled}
                        onClick={async (e) => {
                          try {
                            await handleSubmitClick(e); // Wait for captcha verification
                            // sendMail(); // Call sendEmail() if captcha is verified
                            console.log("error" + e);
                          } catch (error) {
                          }
                        }}
                        style={{ backgroundColor: captchaVerified ? '#28a745' : '' }}
                      >
                        {captchaVerified ? 'Verified' : 'Submit Captcha'}
                      </button>
                    </div>

                    <br /><br />

                    <div className="col-lg-5 col-12 ms-auto">
                      <button type="submit" className="form-control" value="Submit" disabled={!captchaVerified} >
                        Send
                      </button>
                    </div>
                  </div>
                </form>
              </div>

              <div className="col-12">
                <h4 className="mt-5 mb-4">
                  KPCT MALL, Office No. B-3, near Vishal Mega Mart, Fatima Nagar,
                  Wanowrie, Pune, Maharashtra
                </h4>

                <div className="google-map pt-3">
                  <iframe
                    title="Map"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3783.5659619350517!2d73.89822067465174!3d18.503309469702394!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2c1c5f0000001%3A0xcb943e93a77ea76c!2sKPCT%20mall!5e0!3m2!1sen!2sin!4v1715590043958!5m2!1sen!2sin"
                    width="100%"
                    height="300"
                    style={{ border: 0 }}
                    allowFullScreen=""
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Contact;
