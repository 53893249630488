import React from 'react'
import './TermsCondition.css'

const TermsCondition = () => {
  return (
    <>
        <div className="terms-of-use">
            <h1>Terms of Use</h1>
            <p>
                Welcome to Maa Pranaam Suvidha Limited (the "Site"). These Terms of Use ("Terms") govern your access to and use of the Site and its services. By accessing or using the Site, you agree to be bound by these Terms. The Terms are to be read together by you with any terms, conditions or disclaimers provided in the pages of our website. Please review the Terms carefully. If you do not agree to the Terms or our Privacy Policy, you are not authorized to access our website, use any of our website’s services or place an order on our website.
            </p>
            <h2>Eligibility</h2>
            <p>
                This Website is offered and available to users who are 18 years of age or older. By using this Website, you represent and warrant that you are of legal age to form a binding contract with the Company and meet all of the foregoing eligibility requirements. If you do not meet all of these requirements, you must not access or use the Website.
            </p>
            <h2>Use of Website</h2>
            <p>
                You agree to use our website for legitimate purposes and not for any illegal or unauthorized purpose, including without limitation,
            </p>
            <ul>
                <li>In violation of any intellectual property or privacy law.</li>
                <li>In any way that breaches any applicable local, national, or international law or regulation.</li>
                <li>For fraudulent or malicious purposes, or in connection with any unlawful, illegal, fraudulent, or harmful activity.</li>
                <li>To transmit, or procure the sending of, any unsolicited or unauthorized advertising or promotional material or any other form of similar solicitation (spam).</li>
            </ul>
            <h2>Modification to the Terms</h2>
            <p>
                We may revise and update these Terms of Use from time to time in our sole discretion. All changes are effective immediately when we post them and apply to all access to and use of the Website thereafter.
                Your continued use of the Website following the posting of revised Terms of Use means that you accept and agree to the changes. You are expected to check this page from time to time so you are aware of any changes, as they are binding on you.
            </p>
            <h2>Privacy Policy</h2>
            <p>
                Your use of the Website is also subject to the Company’s Privacy Policy. Please review our Privacy Policy, which also governs the Website and informs users of our data collection practices. Your agreement to the Privacy Policy is hereby incorporated into these Terms of Use.
            </p>
        </div>
    </>
  )
}

export default TermsCondition
