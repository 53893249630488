import React from 'react'

const Service7 = () => {
  return (
    <>

            <section className="news-detail section-padding pt-0">
                <div className="container">
                    <div className="row">

                        <div className="col-lg-12 col-12">
                          
                            <div className="col-lg-10 col-10 mx-auto mt-5">
                                
                                <h4 className="mb-3">Audit, Accounting, and Taxation Services</h4>

                                <p>
                                    We provide a comprehensive suite of audit, accounting, and taxation services tailored to meet the needs of your business. Our services include:
                                    </p><br/>
                                
                                

                                <ul className="list">
                                    <li className="list-item">Audit and Assurance: Ensuring compliance with the latest financial regulations and standards, we provide detailed audits that help you understand the true financial state of your business.</li>

                                    <li className="list-item">Accounting Solutions: From day-to-day bookkeeping to complex financial reporting, our services are designed to keep your financial records accurate and up to date.</li>

                                    <li className="list-item">Tax Advisory and Compliance: We offer expert tax advice and planning strategies to optimize your tax position, ensuring compliance with local and international tax laws.</li>
                                    
                                </ul>

                                
                            </div>
                        </div>

                    </div>
                </div>
            </section>
    </>
  )
}

export default Service7
