
import React from 'react';
import "./Overview.css";

const Overview = () => {
  return (

    <>

      <div className="container-overview">
        <header>
          <h3 className="title-overview" style={{ color: "#dc3545" }}>Overview</h3></header>
          <div className="form-overiew">
            <section id="people" className="section">
              <h5 className="sub-title">Our People</h5>
              <p>At Maa Pranaam Suvidha Limited, we are a family of dedicated professionals committed to delivering exceptional and personalized services. Together, we create a supportive and inclusive environment where everyone can thrive.<br />Our mission is to empower our people to reach their full potential. We believe that when our team members grow, our clients and our company benefit as well. We invest in your development, providing opportunities for continuous learning and career advancement.</p>
            </section>
            <section id="purpose" className="section">
              <h5 className="sub-title">Our Purpose</h5>
              <p>Maa Pranaam suvidha Limited is dedicated to delivering exceptional and personalized professional services across a broad spectrum of practice areas, emphasizing robust client advocacy and clear, transparent communication. Our highly skilled team upholds the highest standards of professional excellence, continually advancing their expertise to provide innovative and effective solutions. We prioritize accessibility, responsiveness, and client support, fostering a trustworthy and collaborative relationship. With a strong commitment to community engagement and a global perspective, we strive to make a meaningful impact while building enduring client relationships founded on integrity and mutual respect.</p>
            </section>
            <section id="values" className="section">
              <h5 className="sub-title">Our Values</h5>
              <p>Together, we're committed to making a positive difference in the lives of our clients, our community, and each other. We envision a future where:</p> <ul> <li><strong>Our clients thrive,</strong> thanks to our exceptional services and support.</li> <li><strong>Our community benefits</strong> from our collective efforts and expertise.</li> <li><strong>Our team members grow, develop, and flourish</strong> in a supportive and inclusive environment.</li> </ul>
            </section>
          </div>
      </div>
    </>


  );
};

export default Overview;
