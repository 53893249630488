import React from 'react'
import './Services.css'

const LegalServices = () => {
  const scrollToTop = () => {
    window.scrollTo({
        top: 550,
        behavior: 'smooth'
    });
};
  return (
    <>
      <div className="container-c">
        <div className="content-wrapper-c">
          <div className="text-column-c">
            <div className="image-container-h">
              <h3 className="centered-heading">Legal Services</h3>
            </div>
            <div id="#service9" className="service">
              <div className="content">
                <div className="text-column">
                  <h3>Business Law Management Services</h3>
                  <p>Our Business Law Management Services are designed to support your company in navigating the complex landscape of corporate law. Our offerings include:</p><br />

                  <ul className="list">
                    <li className="list-item">Corporate Governance: Advising on best practices in corporate governance to enhance compliance and operational effectiveness.</li>

                    <li className="list-item">Contract Management: Drafting, reviewing, and negotiating contracts to protect your interests and ensure clarity in business transactions.
                    </li>

                    <li className="list-item">Regulatory Compliance: Ensuring your business adheres to applicable laws and regulations, reducing risk and maintaining your company’s reputation.</li>
                    <li className="list-item">Dispute Resolution: Providing strategic advice and representation in business disputes to resolve conflicts efficiently and favourably

                    </li>
                    <li className="list-item">Intellectual Property Management: Safeguarding your innovations, brands, and creative works through robust intellectual property strategies.</li>

                  </ul>
                </div>
                <div className="image-column">
                  <img src="images/ServicesNavBar/LS1.jpg" alt="Image description" class="img-left" />
                  <a href="/#/contact" className="contactButton" onClick={scrollToTop}>Contact us</a>
                </div>
              </div>
            </div>
            <div id="#service10" className="service">
              <div className="content">
                <div className="text-column">
                  <h3>Employment Management Services</h3>
                  <p>We provide comprehensive Employment Management Services designed to support businesses in managing their workforce effectively. Our services include, but are not limited to, employee recruitment, payroll processing, performance management, compliance with labour laws, and employee training and development. These services are tailored to meet the unique needs of your business, ensuring that your employment practices are efficient, compliant, and aligned with your business objectives.</p>
                </div>
                <div class="image-column">
                  <img src="images/ServicesNavBar/LS2.jpg" alt="Image description" class="img-left" />
                  <a href="/#/contact" className="contactButton" onClick={scrollToTop}>Contact us</a>
                </div>
              </div>
            </div>
            <div id="#service11" className="service">
              <div className="content">
                <div className="text-column">
                  <h3>Intellectual Property</h3>
                  <p>We offer comprehensive Intellectual Property (IP) services to safeguard your creative assets and innovations. Our services include trademark registration, patent applications, copyright protection, IP portfolio management, licensing agreements, and IP litigation support. Whether you're an individual inventor, startup, or established company, we provide tailored solutions to protect and maximize the value of your intellectual property.</p>
                </div>
                <div class="image-column">
                  <img src="images/ServicesNavBar/LS3.jpg" alt="Image description" class="img-left" />
                  <a href="/#/contact" className="contactButton" onClick={scrollToTop}>Contact us</a>
                </div>
              </div>
            </div>
            <div id="#service12" className="service">
              <div className="content">
                <div className="text-column">
                  <h3>Corporate Drafting & Litigation</h3>
                  <p>We providing expert legal support for businesses in drafting corporate documents, contracts, and agreements, as well as representing clients in corporate litigation matters. We offer tailored solutions to address your corporate legal needs efficiently and effectively.</p>
                </div>
              </div>
              <div class="image-column">
                <img src="images/ServicesNavBar/LS4.jpg" alt="Image description" class="img-left" />
                <a href="/#/contact" className="contactButton" onClick={scrollToTop}>Contact us</a>
              </div>
            </div>
            <div id="#service13" className="service">
              <div className="content">
                <div className="text-column">
                  <h3>Legal Drafts Services</h3>
                  <p>We offer specialized Legal Drafts Services aimed at providing clients with precise and customized legal documents tailored to their specific needs. Our offerings include drafting contracts, agreements, business documents, and other legal forms. These services are crafted by experienced legal professionals to ensure they meet relevant legal standards and requirements.</p>
                </div>
              </div>
              <div class="image-column">
                <img src="images/ServicesNavBar/LS5.jpg" alt="Image description" class="img-left" />
                <a href="/#/contact" className="contactButton" onClick={scrollToTop}>Contact us</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default LegalServices
