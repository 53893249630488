import React from 'react'

const Service10 = () => {
    return (
        <>

            <section className="news-detail section-padding pt-0">
                <div className="container">
                    <div className="row">

                        <div className="col-lg-12 col-12">


                            <div className="col-lg-10 col-10 mx-auto mt-5">

                                <h4 className="mb-3">Employment Management Services</h4>

                                <p>We provide comprehensive Employment Management Services designed to support businesses in managing their workforce effectively. Our services include, but are not limited to, employee recruitment, payroll processing, performance management, compliance with labour laws, and employee training and development. These services are tailored to meet the unique needs of your business, ensuring that your employment practices are efficient, compliant, and aligned with your business objectives.


                                </p><br />


                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </>
    )
}

export default Service10
