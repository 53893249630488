import React from 'react'

const Service1 = () => {
  return (
    <>
          <section className="news-detail section-padding pt-0">
                <div className="container">
                    <div className="row">

                        <div className="col-lg-12 col-12">
                           
                            <div className="col-lg-10 col-10 mx-auto mt-5">
                                
                                <h4 className="mb-3">Banking and Financial Services </h4>

                                <p>We provide comprehensive Banking and Financial Services to meet your personal and business needs. Our services include savings accounts, checking accounts, loans, investment options, wealth management, and financial advisory services. Whether you're looking for basic banking solutions or complex financial strategies, our team is here to assist you in achieving your financial goals.

                                </p><br/>
                                                               
                            </div>
                        </div>

                    </div>
                </div>
            </section> 
    </>
  )
}

export default Service1