import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const submit = (e) => {
    e.preventDefault();
    localStorage.setItem('email', email); // Store user name in localStorage


    axios.post('http://localhost:8004/login', {
      email,
      password
    })
    .then(response => {
      console.log(response);
      navigate('/');
    })
    .catch(error => {
      console.error('Error logging in:', error);
      setError('Invalid email or password');
    });
  };

  return (
    <div className="login container d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
      <div className="card shadow p-5" style={{ width: '400px' }}>
        <h1 className="text-center mb-4">Login</h1>

        <form onSubmit={submit}>
          <div className="mb-3">
            <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} className="form-control" placeholder="Email" />
          </div>
          <div className="mb-3">
            <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} className="form-control" placeholder="Password" />
          </div>
          {error && <div className="text-danger">{error}</div>}
          <button type="submit" className="btn btn-primary w-100">Login</button>
        </form>

        <hr className="my-4" />

        <div className="text-center">
          <p>OR</p>
          <Link to="/signup" className="btn btn-outline-secondary">Signup Page</Link>
        </div>
      </div>
    </div>
  );
}

export default Login;
