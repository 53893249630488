import React from 'react'

const Service4 = () => {
  return (
    <>
      
      <section className="news-detail section-padding pt-0">
                <div className="container">
                    <div className="row">

                        <div className="col-lg-12 col-12">
                           
                            <div className="col-lg-10 col-10 mx-auto mt-5">
                                
                                <h4 className="mb-3">Company Registration And Compliance</h4>

                                <p>We specialize in Company Registration and Compliance services, assisting businesses with the formation and registration of companies, partnerships, and other business entities. Our services also encompass ongoing compliance support, including annual filings, regulatory compliance, corporate governance, and statutory obligations.

                                </p><br/>     
                                
                            </div>
                        </div>

                    </div>
                </div>
            </section>
    </>
  )
}

export default Service4
