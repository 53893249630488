import React from 'react'

const Services = () => {
  return (
    <>
<div>
  <header className="site-header site-menu-header">
    <div className="container">
      <div className="row">
        <div className="col-lg-10 col-12 mx-auto">
          <h1 className="text-white">Our Services</h1>
          <strong className="text-white">
            "At Maa Pranaam Suvidha Limited, excellence is not an option; it's
            our standard. We strive for perfection in everything we do."
          </strong>
        </div>
      </div>
    </div>
    <div className="video-wrap">
      <video autoPlay="" loop="" muted="" className="custom-video" poster="">
        <source src="video/services_tiny.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
    <div className="overlay" />
  </header>
  <section className="menu section-padding">
    <div className="container">
      <div className="row">
        <div className="col-12">
          <h2 className="mb-lg-5 mb-4">Company Secretary</h2>
        </div>
        <div className="col-lg-4 col-md-6 col-12">
          <div className="menu-thumb">
            <a href="Service1">
              <img
                src="images/OurServices/pexels-tranmautritam-225502.jpg"
                className="img-fluid menu-image"
                alt=""
              />
            </a>
            <div className="menu-info d-flex flex-wrap align-items-center">
              <h4 className="mb-0">Structuring and Organizing Business</h4>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 col-12">
          <div className="menu-thumb">
            <a href="Service2">
              <img
                src="images/OurServices/people-1979261_640.jpg"
                className="img-fluid menu-image"
                alt=""
              />
            </a>
            <div className="menu-info d-flex flex-wrap align-items-center">
              <h4 className="mb-0">Foreign Direct Investment</h4>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 col-12">
          <div className="menu-thumb">
            <a href="Service3">
              <img
                src="images/OurServices/document-428331_640.jpg"
                className="img-fluid menu-image"
                alt=""
              />
            </a>
            <div className="menu-info d-flex flex-wrap align-items-center">
              <h4 className="mb-0">All kind of Licence For Company</h4>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 col-12">
          <div className="menu-thumb">
            <a href="Service4">
              <img
                src="images/OurServices/pexels-fauxels-3184632.jpg"
                className="img-fluid menu-image"
                alt=""
              />
            </a>
            <div className="menu-info d-flex flex-wrap align-items-center">
              <h4 className="mb-0">Company Registration And Compliance</h4>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 col-12">
          <div className="menu-thumb">
            <a href="Service5">
              <img
                src="images/OurServices/laptop-3196481_640.jpg"
                className="img-fluid menu-image"
                alt=""
              />
            </a>
            <div className="menu-info d-flex flex-wrap align-items-center">
              <h4 className="mb-0">Merger and Acquisition </h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section className="menu section-padding bg-white">
    <div className="container">
      <div className="row">
        <div className="col-12">
          <h2 className="mb-lg-5 mb-4">Chartered Accountant</h2>
        </div>
        <div className="col-lg-4 col-md-6 col-12">
          <div className="menu-thumb">
            <a href="Service6">
              <img
                src="images/OurServices/pexels-kampus-8441811.jpg"
                className="img-fluid menu-image"
                alt=""
              />
            </a>
            <div className="menu-info d-flex flex-wrap align-items-center">
              <h4 className="mb-0">Audit, Accounting, and Taxation Services</h4>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 col-12">
          <div className="menu-thumb">
            <a href="Service7">
              <img
                src="images/OurServices/document-428331_640.jpg"
                className="img-fluid menu-image"
                alt=""
              />
            </a>
            <div className="menu-info d-flex flex-wrap align-items-center">
              <h4 className="mb-0">Banking and Financial Services </h4>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 col-12">
          <div className="menu-thumb">
            <a href="Service8">
              <img
                src="images/OurServices/tax-468440_1280.jpg"
                className="img-fluid menu-image"
                alt=""
              />
            </a>
            <div className="menu-info d-flex flex-wrap align-items-center">
              <h4 className="mb-0">GST And Other Related Services </h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section className="menu section-padding">
    <div className="container">
      <div className="row">
        <div className="col-12">
          <h2 className="mb-lg-5 mb-4">Legal Advisor</h2>
        </div>
        <div className="col-lg-4 col-md-6 col-12">
          <div className="menu-thumb">
            <a href="Service9">
              <img
                src="images/OurServices/pexels-august-de-richelieu-4427611.jpg"
                className="img-fluid menu-image"
                alt=""
              />
            </a>
            <div className="menu-info d-flex flex-wrap align-items-center">
              <h4 className="mb-0">Business Law Management Services</h4>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 col-12">
          <div className="menu-thumb">
            <a href="Service10">
              <img
                src="images/OurServices/job-4131482_640.jpg"
                className="img-fluid menu-image"
                alt=""
              />
            </a>
            <div className="menu-info d-flex flex-wrap align-items-center">
              <h4 className="mb-0">Employment Management Services</h4>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 col-12">
          <div className="menu-thumb">
            <a href="Service11">
              <img
                src="images/OurServices/pexels-kampus-8441811.jpg"
                className="img-fluid menu-image"
                alt=""
              />
            </a>
            <div className="menu-info d-flex flex-wrap align-items-center">
              <h4 className="mb-0">Intellectual Property</h4>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 col-12">
          <div className="menu-thumb">
            <a href="Service12">
              <img
                src="images/OurServices/notebook-2637726_640.jpg"
                className="img-fluid menu-image"
                alt=""
              />
            </a>
            <div className="menu-info d-flex flex-wrap align-items-center">
              <h4 className="mb-0">Corporate Drafting and Litigation </h4>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 col-12">
          <div className="menu-thumb">
            <a href="Service13">
              <img
                src="images/OurServices/study-2746004_640.jpg"
                className="img-fluid menu-image"
                alt=""
              />
            </a>
            <div className="menu-info d-flex flex-wrap align-items-center">
              <h4 className="mb-0">Legal Drafts Services</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>

    </>
  )
}

export default Services
