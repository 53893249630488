import React from 'react'
import Trends from './Trends/Trends'
import OurThinking from './OurThinking/OurThinking'
import ClientReviews from './ClientReviews/ClientReviews'
import { OurServices } from './OurServices/OurServices'

const BodyContent = () => {

    // const email = localStorage.getItem('email');
    // const isLoggedIn = email !== null;

    // function removeAfterAt(email) {
    //     if (email === null) {
    //         return ''; // Return an empty string or handle the null case appropriately
    //     }
    //     const atIndex = email.indexOf('@');
    //     return atIndex !== -1 ? email.substring(0, atIndex) : email;
    // }

    // const usernameOnly = removeAfterAt(email);
    return (
        <>
            {/* <div>
                {isLoggedIn && ( // Render the code only if the user is logged in
                    <div className="home container">
                        <h6>Welcome, {usernameOnly}</h6>
                    </div>
                )}
            </div> */}

            <section className="hero">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-5 col-12">
                            <div className="heroText text-lg-right">
                                <h2 className="text-white mb-lg-5 mb-3">Maa Pranaam Suvidha Limited</h2>
                                <h6 className="text-white mb-lg-5 mb-3">Pioneering Sustainable Solutions for Enduring Prosperity</h6>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="video-wrap">
                <video autoPlay loop muted className="custom-video" poster="">
                            <source src="video/home_particals.mp4" type="video/mp4" />
                             Your browser does not support the video tag.
                </video>
                    {/* <img src="video/home_particals.mp4" className="custom-video" alt='topImg' /> */}
                {/* images/ServiceIMG/MainBg.jpg */}
                </div>
                <div className="overlay"></div>
            </section>

            <div>
                <Trends />
            </div>
              <OurServices />
            <div>  
                <OurThinking />
            </div>
               <ClientReviews />
        </>
    )
}

export default BodyContent
