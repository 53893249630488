import React from 'react'

const Career = () => {
    return (
    <>
            <div>

                <header className="site-header site-news-header">
                    <div className="container">
                        <div className="row">

                            <div className="col-lg-10 col-12 mx-auto">
                                <h1 className="text-white">We Are Hiring!</h1>

                                <strong className="text-white">Our Company Needs Fresh Minds!</strong>
                            </div>

                        </div>
                    </div>
                    <div className="video-wrap">
                        <video autoPlay loop muted className="custom-video" poster="">
                            <source src="video/career_tiny.mp4" type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                    </div>
                    <div className="overlay"></div>
                </header>


                <section className="news-detail section-padding pt-0" />
                <div className="container">
                    <div className="row">



                        <h4 className="mb-lg-4">Apply here</h4>

                        <p>We nurture and cultivate a sense of pride in the work and create team leaders. Your growth chart will be governed by well-defined transparent policies of the firm. Apply via the form given below and meet us for face-to-face interview.</p>



                        <form className="booking-form row" action="#" method="post">
                            <div className="col-lg-6 col-12">
                                <label htmlFor="name" className="form-label">Full Name</label>

                                <input type="text" name="name" id="name" className="form-control" placeholder="Your Name" required />
                            </div>

                            <div className="col-lg-6 col-12">
                                <label htmlFor="email" className="form-label">Email Address</label>

                                <input type="email" name="email" id="email" pattern="[^ @]*@[^ @]*" className="form-control" placeholder="your@email.com" required />
                            </div>

                            <div className="col-lg-6 col-12">
                                <label htmlFor="phone" className="form-label">Phone Number</label>

                                <input type="telephone" name="phone" id="phone" pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}" className="form-control" placeholder="123-456-7890" />
                            </div>


                            <div className="col-lg-6 col-12">
                                <label htmlFor="gender" className="form-label">Gender</label>

                                <select className="form-select form-control" name="gender" id="gender">
                                    <option value="5" selected>Gender</option>
                                    <option value="6">male</option>
                                    <option value="6">female</option>
                                    <option value="7">other</option>

                                </select>
                            </div>

                            <div className="col-lg-6 col-12">
                                <label htmlFor="experience" className="form-label">Year of Experience</label>

                                <select className="form-select form-control" name="experience" id="experience">
                                    <option value="5" selected>Selected</option>
                                    <option value="5">Fresher</option>
                                    <option value="5">1 To 5 Years</option>
                                    <option value="6">5 To 7 Years</option>
                                    <option value="6">And More Than 7 Years</option>


                                </select>
                            </div>


                            <div className="col-lg-6 col-12">
                                <label htmlFor="position" className="form-label">Position</label>

                                <select className="form-select form-control" name="position" id="position">
                                    <option value="5" selected>Position</option>
                                    <option value="6">Company Secratary</option>
                                    <option value="6">Company Secratary Intern</option>
                                    <option value="6">Chartered Accountant</option>
                                    <option value="6">Chartered Accountant Intern</option>
                                    <option value="7">Accountant</option>
                                    <option value="6">Advocate</option>
                                    <option value="6">Advocate Intern</option>



                                </select>
                                <div className="col-lg-6 col-12">
                                    <label htmlFor="date" className="form-label">Birth Date</label>

                                    <input type="date" name="date" id="date" value="" className="form-control" />
                                </div>

                                <div className="col-lg-6 col-12">
                                    <label htmlFor="myImage" className="form-label">Resume</label>

                                    <input type="file" name="myImage" id="myImage" className="form-control" placeholder="Resume" accept=".png, .gif, .jpeg, .pdf" />
                                </div>
                              <br /><br />

                                <div className="col-lg-4 col-12 ms-auto">
                                    <button type="submit" className="form-control">Submit</button>
                                </div>
                                <br /><br />

                            </div>

                        </form>

                    </div>
                </div>
          </div>
    </>
            )
}

 export default Career
