import React from 'react';
import './PrivacyPolicy.css';

const PrivacyPolicy = () => {
    return (
        <div className="privacy-policy-container">
            <header className='privacy'>
                <h4>PRIVACY POLICY</h4>
                <p>This privacy policy (the "PRIVACY POLICY") applies to your use of the website of Maa Pranaam Suvidha Limited. This Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your information when You use the Service and tells You about Your privacy rights and how the law protects You.</p>
            </header>
            <main>
                <section>
                    <h2>SCOPE</h2>
                    <p>This Privacy policy covers our information practices, including how we collect, use, share and protect the personal information you provide to us, through our web sites that link to this Privacy policy (collectively referred to as “Maa Websites”).</p>
                </section>
                <section>
                    <h2>PERSONAL INFORMATION</h2>
                    <p>The term "PERSONAL INFORMATION" means information that you provide to us which personally identifies you to be contacted or identified, such as your name, phone number, email address, and any other data that is tied to such information.</p>
                    <p>Our practices and procedures in relation to the collection and use of Personal Information have been set-out below in order to ensure safe usage of the Website for you.</p>
                </section>
                <section>
                    <h2>COLLECTING AND USING PERSONAL DATA</h2>
                    <p>PERSONAL DATA</p>
                    <p>While using Our Service, we may ask You to provide Us with certain personally identifiable information that can be used to contact or identify You. Personally, identifiable information may include, but is not limited to:</p>
                    <ul>
                        <li>Email address</li>
                        <li>First name and last name</li>
                        <li>Phone number</li>
                        <li>Usage Data</li>
                        <li>Job titles</li>
                        <li>Organization name</li>
                        <li>Country, city</li>
                        <li>IP address and device address</li>
                    </ul>
                </section>
                <section>
                    <h2>USAGE DATA</h2>
                    <p>Usage Data is automatically collected when utilizing the Service.</p>
                    <p>Such Usage Data may encompass information including, but not limited to, the Internet Protocol (IP) address of Your Device, browser type, browser version, the specific pages of the Service that You visit, the time and date of Your visit, the duration spent on those pages, unique device identifiers, and other diagnostic data.</p>
                    <p>In instances where You access the Service via a mobile device, We may automatically collect certain information, including, but not limited to, the type of mobile device used, the unique identifier of Your mobile device, the IP address of Your mobile device, the mobile operating system, the type of mobile Internet browser employed, unique device identifiers, and other diagnostic data.</p>
                </section>
                <section>
                    <h2>HOW WE USE YOUR DATA</h2>
                    <p>Our use of Your Personal Data is for the following purposes:</p>
                    <ul>
                        <li>Provide information and services as requested by you.</li>
                        <li>Assess queries, requirements, and process requests for products and services.</li>
                        <li>Perform client communication, service, billing, and administration.</li>
                        <li>Maintain leads</li>
                        <li>Create brand awareness</li>
                        <li>Provide better services and generate demand</li>
                    </ul>
                </section>
                <section>
                    <h2>COOKIES</h2>
                    <p>We use both Session and Persistent Cookies for the purposes set out below:</p>
                    <ul>
                        <li><strong>Essential Cookies Type:</strong> These Cookies are essential to provide You with services available through the Website and to enable You to use some of its features. They help to authenticate users and prevent fraudulent use of user accounts. Without these Cookies, the services that You have asked for cannot be provided, and We only use these Cookies to provide You with those services.</li>
                        <li><strong>Cookies Policy:</strong> These Cookies identify if users have accepted the use of cookies on the Website.</li>
                        <li><strong>Functionality Cookies Type:</strong> These Cookies allow us to remember choices You make when You use the Website, such as remembering your login details or language preference. The purpose of these Cookies is to provide You with a more personal experience and to avoid You having to re-enter your preferences every time You use the Website.</li>
                    </ul>
                </section>
                <section>
                    <h2>DISCLOSURE OF INFORMATION</h2>
                    <p>Maa Pranaam may share your personal information with your consent, or where the disclosure is necessary for compliance of a legal obligation or where required by government agencies mandated under law to procure such disclosure. It shall also take steps to ensure that the information transferred to a third party is not further disclosed by it except where permissible under law.</p>
                </section>
                <section>
                    <h2>EMAIL</h2>
                    <p>You can contact us at the e-mail or postal address listed in the "Contact Us" section at the bottom of this Policy. Please include your current contact information, the information you are interested in accessing and your requests with regard to the same if any. We will endeavor to respond to your request, subject to reasonable limitations provided by law unless it infringes on the privacy of other individuals.</p>
                    <p>If we are unable to entertain your request, we will provide you with the reason for refusal and inform you of any exceptions relied upon.</p>
                </section>
                <section>
                    <h2>SECURITY OF PERSONAL DATA</h2>
                    <p>The security of Your Personal Data is important to Us. However, please be aware that no method of transmitting data over the Internet or storing it electronically is completely secure. While We make every effort to use commercially acceptable methods to protect Your Personal Data, We cannot guarantee its absolute security.</p>
                </section>
                <section>
                    <h2>MODIFICATION TO THIS POLICY</h2>
                    <p>Our Privacy Policy is subject to change at any time without notice. To make sure you are aware of any changes, please review this policy periodically. These changes will be effective immediately on the customer of Maa Pranaam. Please note that at all times you are responsible for updating your Personal Information, including to provide us with your most current e-mail address.</p>
                </section>
                <section>
                    <h2>APPLICABLE LAWS</h2>
                    <p>The use of this Website shall be governed by and construed in accordance with the laws of India. The Users hereby agree that any legal actions or proceedings arising out of or in connection with their use of the Website shall be brought exclusively before the competent courts or tribunals in Pune, India. The Users
                        irrevocably submit to the jurisdiction of such courts or tribunals.</p>
                </section>
                <section>
                    <h2>GRIEVANCE REDRESSAL</h2>
                    <p>Any grievance or complaint, in relation to the processing of personal information, should be sent to Maa Pranaam in writing to the below-mentioned email ID. Grievances shall be redressed as expeditiously as possible. The contact information for the Grievance Officer is <br />Mr. ______________________, <br />E-Mail address:- ________________________________________.</p>
                </section>
            </main>
        </div>
    );
};

export default PrivacyPolicy