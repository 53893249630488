import React, { useState, useRef, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import './NavbarCSS.css';
import NavAbout from './NavContent/NavAbout';

const Navbar = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [activeItem, setActiveItem] = useState('');
    const [isVisible, setIsVisible] = useState(false);
    const [isVisibleAbout, setIsVisibleAbout] = useState(false);


    const divRef = useRef(null);
    const AboutRef = useRef(null);

    const handleClick = () => {
        setIsVisible(true);
    };

    const handleClickAbout = () => {
        setIsVisibleAbout(true);
    }

    const handleClickOutside = (event) => {
        if (divRef.current && !divRef.current.contains(event.target)) {
            setIsVisible(false);
        }
        if (AboutRef.current && !AboutRef.current.contains(event.target)) {
            setIsVisibleAbout(false);
        }
    };

    const handleItemClick = (item) => {
        setActiveItem(item);
    };

    const handleServiceClick = (path, fragment) => {
        navigate(`${path}#${fragment}`);
        setIsVisible(false);
    };


    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <>
            <nav className="navbar navbar-expand-lg bg-white shadow-lg">
                <div className="container">
                    <button className="navbar-toggler collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <a className="navbar-brand ad" href="/">
                        <img src="images/logo/logo-img.jpeg" alt="logo img" width="80" height="65" />
                        <div className='LogoTxt'>
                            Maa Pranaam <br /> <center>Suvidha <small>Ltd.</small></center>
                        </div>
                    </a>
                    <div className="collapse navbar-collapse" id="navbarNav">
                        <ul className="navbar-nav mx-auto">
                            <li className="nav-item">
                                <Link className={`nav-link ${location.pathname === '/' ? 'active' : ''}`} to="/" onClick={() => handleItemClick('home')}>Home</Link>
                            </li>
                            <li className="nav-item dropdown">
                                <Link className={`nav-link dropdown-toggle ${location.pathname === '/about' ? 'active' : ''}`} to="/about" onClick={handleClickAbout}>About Us</Link>
                            </li>
                            <li className="nav-item dropdown">
                                <Link className={`nav-link dropdown-toggle ${location.pathname === '/services' ? 'active' : ''}`} to="/services" onClick={handleClick}>Our Services</Link>
                            </li>
                            <li className="nav-item">
                                <Link className={`nav-link ${location.pathname === '/career' ? 'active' : ''}`} to="/career" onClick={() => handleItemClick('career')}>Career</Link>
                            </li>
                            <li className="nav-item">
                                <Link className={`nav-link ${location.pathname === '/contact' ? 'active' : ''}`} to="/contact" onClick={() => handleItemClick('contact')}>Contact</Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
            {isVisible && (
                <div ref={divRef} style={{
                    padding: '20px',
                    position: 'absolute',
                    zIndex: 3,
                    width: '100%',
                    background: '#fffdf0',
                }}>
                    <div className="container services_con">
                        <div className="row mb-3">
                            <div className="col">
                                <h6 className='services_head'>Corporate Compliances</h6>
                                <ul className='services_UL'>
                                    <li><button className="dropdown-item" onClick={() => handleServiceClick('/corporate','service1')}>Structuring and Organizing Business</button></li>
                                    <li><button className="dropdown-item" onClick={() => handleServiceClick('/corporate','service2')}>Foreign Direct Investment</button></li>
                                    <li><button className="dropdown-item" onClick={() => handleServiceClick('/corporate','service3')}>Business License Spectrum</button></li>
                                    <li><button className="dropdown-item" onClick={() => handleServiceClick('/corporate','service4')}>Merger and Acquisition</button></li>
                                    <li><button className="dropdown-item" onClick={() => handleServiceClick('/corporate','service5')}>Company Registration And Compliance</button></li>
                                </ul>
                            </div>
                            <div className="col">
                                <h6 className='services_head'>Audit & Taxation</h6>
                                <ul className='services_UL'>
                                    <li><button className="dropdown-item" onClick={() => handleServiceClick('/audit', 'service6')}>Banking & Financial Services</button></li>
                                    <li><button className="dropdown-item" onClick={() => handleServiceClick('/audit', 'service7')}>Audit, Accounting & Taxation Services</button></li>
                                    <li><button className="dropdown-item" onClick={() => handleServiceClick('/audit', 'service8')}>GST & Other Related Services</button></li>
                                </ul>
                            </div>
                            <div className="col">
                                <h6 className='services_head'>Legal Services</h6>
                                <ul className='services_UL'>
                                    <li><button className="dropdown-item" onClick={() => handleServiceClick('/legal', 'service9')}>Business Law Management Services</button></li>
                                    <li><button className="dropdown-item" onClick={() => handleServiceClick('/legal', 'service10')}>Employment Management Services</button></li>
                                    <li><button className="dropdown-item" onClick={() => handleServiceClick('/legal', 'service11')}>Intellectual Property</button></li>
                                    <li><button className="dropdown-item" onClick={() => handleServiceClick('/legal', 'service12')}>Corporate Drafting & Litigation</button></li>
                                    <li><button className="dropdown-item" onClick={() => handleServiceClick('/legal', 'service13')}>Legal Drafts Services</button></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            )}


            {isVisibleAbout && (
                <div ref={AboutRef} style={{
                    padding: '5px',
                    position: 'absolute',
                    zIndex: 3,
                    width: '100%',
                    background: '#fffdf0',
                }}>
                  <NavAbout/>
                </div>
            )}
        </>
    );
}

export default Navbar;
